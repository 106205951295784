<template>
  <div>
    <div id="colorbar">
      <div class="colorbar_sub">
        <div v-for="(item, idx) in priorities" :key="idx">
          <v-checkbox
            v-if="idx"
            class="priority"
            readonly="true"
            :label="item"
            :ripple="false"
            :color="colors[idx - 1]"
            v-model="isTrue"
            value="true"
          ></v-checkbox>
          <p v-else class="priority">Job Priority:</p>
        </div>
      </div>
    </div>
    <div id="container" v-show="isLoaded">
      <ejs-schedule
        ref="scheduleObj"
        height="90vh"
        id="Schedule"
        :selectedDate="selectedDate"
        :eventSettings="eventSettings"
        :showQuickInfo="true"
        :currentView="currentView"
        :dragStop="dragStop"
        :renderCell="onRenderCell"
        :rowAutoHeight="true"
        :eventRendered="onEventRendered"
        :group="group"
        :actionBegin="actionBegin"
        :allowResizing="true"
        :allowAdding="false"
        :allowDeleting="false"
        :resourceHeaderTemplateId="resourceHeaderTemplate"
        :editorTemplate="'schedulerEditorTemplate'"
      >
        <template v-slot:schedulerEditorTemplate="{}">
          <table class="custom-event-editor" width="100%" cellpadding="5">
            <tbody>
              <tr>
                <td class="e-textlabel">Job Num</td>
                <td colspan="4">
                  <input
                    class="e-field e-input"
                    readonly
                    type="text"
                    name="JobHead_JobNum"
                  />
                </td>
              </tr>
              <tr>
                <td class="e-textlabel">Job Type</td>
                <td colspan="4">
                  <input
                    class="e-field e-input"
                    readonly
                    type="text"
                    name="JobHead_JobType"
                  />
                </td>
              </tr>
              <tr>
                <td class="e-textlabel">Part Num</td>
                <td colspan="4">
                  <input
                    class="e-field e-input"
                    readonly
                    type="text"
                    name="JobHead_PartNum"
                  />
                </td>
              </tr>
              <tr>
                <td class="e-textlabel">Serial Num</td>
                <td colspan="4">
                  <input
                    class="e-field e-input"
                    readonly
                    type="text"
                    name="Equip_SerialNum"
                  />
                </td>
              </tr>
              <tr>
                <td class="e-textlabel">Start Date</td>
                <td colspan="4">
                  <input class="e-field e-input" type="date" name="StartTime" />
                </td>
              </tr>
              <tr>
                <td class="e-textlabel">Start Hour</td>
                <td colspan="4">
                  <input
                    class="e-field e-input"
                    type="number"
                    name="JobOper_StartHour"
                  />
                </td>
              </tr>
              <tr>
                <td class="e-textlabel">Due Date</td>
                <td colspan="4">
                  <input class="e-field e-input" type="date" name="EndTime" />
                </td>
              </tr>
              <tr>
                <td class="e-textlabel">Due Hour</td>
                <td colspan="4">
                  <input
                    class="e-field e-input"
                    type="number"
                    name="JobHead_DueHour"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </template>
        <e-views>
          <e-view option="Week"></e-view>
          <e-view option="TimelineWeek"></e-view>
          <e-view option="Month"></e-view>
          <e-view option="TimelineMonth"></e-view>
          <e-view option="Agenda">List View</e-view>
        </e-views>
        <e-resources>
          <e-resource
            :field="field"
            title="resources"
            name="resources"
            :dataSource="resourceDataSource"
            :textField="textField"
            :idField="idField"
          >
          </e-resource>
        </e-resources>
      </ejs-schedule>
    </div>
    <div :style="cssLayout" v-show="!isLoaded">
      <Loading
        :color="'#006680'"
        :secondaryColor="'#006680'"
        :size="'70rem'"
      ></Loading>
    </div>
  </div>
</template>
<script
  src="https://kit.fontawesome.com/a076d05399.js"
  crossorigin="anonymous"
></script>
<script>
import Vue from "vue";
import { mapGetters } from "vuex";
import DecodeJWT from "../DecodeJWT";
import Loading from "../../loading/Loading.vue";
import { callMethodFunc } from "../../../store/Method/index";
import {
  // ScheduleComponent,
  Day,
  Week,
  Month,
  Agenda,
  TimelineMonth,
  Resize,
  DragAndDrop,
  TimelineViews,
  ExcelExport,
  SchedulePlugin,
  MonthAgenda,
  ResourceDirective,
  ResourcesDirective,
} from "@syncfusion/ej2-vue-schedule";
import LICIENSE from "../../../utils/LICIENSE";
import { registerLicense } from "@syncfusion/ej2-base";
registerLicense(LICIENSE);
var initialLoad = true;
Vue.use(SchedulePlugin);
var resourceHeaderTemplateVue = Vue.component("resource-headerTemplate", {
  template:
    '<div className="template-wrap"><div class="employee-category"><div><img class="employee-image" :src="getImage" :alt="getImage"/></div><div class="employee-name">' +
    '{{getEmployeeName(data)}}</div><div class="employee-designation">{{getEmployeeDesignation(data)}}</div></div></div>',
  data() {
    return {
      data: {},
    };
  },
});
export default {
  components: {
    "e-resource": ResourceDirective,
    "e-resources": ResourcesDirective,
    Loading,
  },
  watch: {
    getJobs() {
      this.eventSettings = { dataSource: this.getJobs };
      var spanElements = document.querySelectorAll("span.e-tbar-btn-text");
      spanElements.forEach(function (span) {
        if (span.textContent.toLowerCase().trim() == "agenda") {
          span.textContent = "List View";
        }
      });
    },
  },
  data: function () {
    return {
      isTrue: "true",
      a: undefined,
      b: 1,
      isLoaded: false,
      allowMultiple: true,
      views: [
        "Day",
        "Week",
        "Month",
        "Agenda",
        "TimelineWeek",
        "TimelineMonth",
        "ExcelExport",
        "MonthAgenda",
      ],
      textField: "resourceName",
      idField: "resourceID",
      field: "resourceID",
      eventSettings: {
        dataSource: [],
        allowDeleting: false,
        allowAdding: false,
      },
      resourceDataSource: [],
      currentView: "TimelineWeek",
      group: {
        resources: ["resources"],
      },
      priorities: [
        "",
        "High",
        "Immediate",
        "Low",
        "Normal",
        "Very High",
        "Very Low",
      ],
      colors: [],
      selectedDate: new Date(),
      cssClass: "excel-export",
      curItem: {},
      resourceHeaderTemplate: function (e) {
        console.log(e);
        return {
          template: resourceHeaderTemplateVue,
        };
      },
    };
  },
  computed: {
    ...mapGetters("Data", [
      "getJobSelecteds",
      "getJobs",
      "getEquipments",
      "getSelection",
      "getSelectionType",
    ]),
    cssLayout() {
      return {
        position: "fixed",
        display: "flex",
        height: "100vh",
        "background-color": "rgba(153, 151, 151, 0.5)",
        "justify-content": "center",
        "align-items": "center",
        width: "100%",
        "z-index": 999999,
      };
    },
  },
  async mounted() {
    let list = document.getElementsByTagName("div");
    for (let i = list.length - 1; i > 0; i--) {
      let item = list[i];
      let text = item.innerHTML;
      if (
        text.includes("This application was built") ||
        text.includes("Syncfusion")
      ) {
        item.style.display = "none";
      }
    }

    let string = [];
    let filSelection = this.getSelection.map((el) => {
      return el.split(": ")[0];
    });
    filSelection.forEach((el) => {
      let e = this.getEquipments.find((c) => {
        return c.equipID == el;
      });
      if (e && e.imgID != "") string.push(`'${e.imgID}'`);
    });
    let where = string.join(",");
    let bo = "Erp.BO.Image";
    let method1 = "GetRows";
    let body1 = {
      whereClauseImage: `ImageID in (${where})  BY ImageID`,
      pageSize: 0,
      absolutePage: 0,
    };
    await callMethodFunc(bo, method1, body1).then((res1) => {
      if (res1 && res1.message == "error") {
        alert(res1.error);
      } else if (
        res1 &&
        res1.data &&
        JSON.parse(res1.data) &&
        JSON.parse(res1.data).data &&
        JSON.parse(JSON.parse(res1.data).data).returnObj
      ) {
        let Image = JSON.parse(JSON.parse(res1.data).data).returnObj.Image;
        let temp = [];
        this.getEquipments.forEach((el) => {
          let a = Image.find((el1) => {
            return el1.ImageID == el.imgID;
          });
          el.base64 = a ? a.ImageContent : "";
          temp.push(el);
        });
        this.getEquipments = temp;
      }
      this.isLoaded = true;
      this.loadResource();
    });

    let token = localStorage.getItem("MTNToken");
    if (token == "" || !token) {
      this.$router.push("/login");
    } else {
      var info = DecodeJWT(token);
      if (info.exp <= new Date().getTime() / 1000) {
        this.$router.push("/login");
        localStorage.setItem("MTNToken", "");
        localStorage.setItem("userinfo", "");
        localStorage.setItem("cust_num", "");
        localStorage.setItem("cust_uid", "");
      } else {
        if (this.getJobSelecteds.length < 1) {
          alert("Please select Job first!");
          this.$router.push("/job-selection");
        } else {
          this.generateDistinctColors(6);
        }
      }
    }
  },
  provide: {
    schedule: [
      Day,
      Week,
      Month,
      Agenda,
      TimelineViews,
      TimelineMonth,
      DragAndDrop,
      Resize,
      ExcelExport,
      MonthAgenda,
    ],
  },
  methods: {
    loadResource() {
      this.resourceDataSource = [];
      let jobs = [];
      let resourceDataSource = [];
      let resourceGroup = [];
      let equipList = [];
      let projectList = [];
      this.getJobSelecteds.forEach((el) => {
        let bc = 0;
        jobs.forEach((el1) => {
          if (el.JobHead_JobNum == el1.JobHead_JobNum) bc++;
        });
        if (bc == 0) {
          const [day, month, year] =
            el.JobHead_StartDate.split("/").map(Number);
          const [day1, month1, year1] =
            el.JobHead_DueDate.split("/").map(Number);
          jobs.push({
            Id: el.JobHead_JobNum,
            Subject: el.Calculated_IsPreventJob
              ? "*" + el.JobHead_JobNum
              : el.JobHead_JobNum,
            StartTime: new Date(
              new Date(`${year}-${month}-${day}`).setHours(
                el.JobHead_StartHour ? el.JobHead_StartHour : 0
              )
            ),
            // StartTime: new Date(new Date(el.JobHead_StartDate).setHours(el.JobHead_StartHour ? el.JobHead_StartHour : 0)),
            // EndTime: new Date(
            //   new Date(el.JobHead_DueDate).setHours(el.JobHead_DueHour == el.JobHead_StartHour && el.JobHead_StartDate == el.JobHead_DueDate ? el.JobHead_DueHour + 9 : el.JobHead_DueHour + 1)
            // ),
            EndTime: new Date(
              new Date(`${year1}-${month1}-${day1}`).setHours(
                el.JobHead_DueHour == el.JobHead_StartHour &&
                  el.JobHead_StartDate == el.JobHead_DueDate
                  ? el.JobHead_DueHour + 9
                  : el.JobHead_DueHour
              )
            ),
            IsAllDay: false,
            equipID: el.JobHead_EquipID,
            projectID: el.JobHead_ProjectID,
            priority: el.JobHead_SchedCode,
            groupID: el.JobOpDtl_ResourceGrpID,
            resourceID: el.Resource_ResourceID,
            Calculated_IsPreventJob: el.Calculated_IsPreventJob,
            Equip_SerialNum: el.Equip_SerialNum,
            JobHead_DueDate: el.JobHead_DueDate,
            JobHead_DueHour: el.JobHead_DueHour,
            JobHead_EquipID: el.JobHead_EquipID,
            JobHead_JobNum: el.JobHead_JobNum,
            JobHead_JobType: el.JobHead_JobType,
            JobHead_PartDescription: el.JobHead_PartDescription,
            JobHead_PartNum: el.JobHead_PartNum,
            JobHead_PhaseID: el.JobHead_PhaseID,
            JobHead_Plant: el.JobHead_Plant,
            JobHead_ProjectID: el.JobHead_ProjectID,
            JobHead_ReqDueDate: el.JobHead_ReqDueDate,
            JobHead_SchedCode: el.JobHead_SchedCode,
            JobHead_SchedLocked: el.JobHead_SchedLocked,
            JobHead_SchedPriority: el.JobHead_SchedPriority,
            JobHead_SchedStatus: el.JobHead_SchedStatus,
            JobHead_StartDate: el.JobHead_StartDate,
            JobHead_StartHour: el.JobHead_StartHour,
            JobOper_DueDate: el.JobOper_DueDate,
            JobOper_DueHour: el.JobOper_DueHour,
            JobOper_LoadHour: el.JobOper_LoadHour,
            JobOper_OpDesc: el.JobOper_OpDesc,
            JobOper_OprSeq: el.JobOper_OprSeq,
            JobOper_StartDate: el.JobOper_StartDate,
            JobOper_StartHour: el.JobOper_StartHour,
            Resource_Description: el.Resource_Description,
            Resource_ResourceID: el.Resource_ResourceID,
          });
          switch (this.getSelectionType) {
            case "e":
              var a = 0;
              equipList.forEach((el1) => {
                if (el.JobHead_EquipID == el1.equipID) {
                  a = a + 1;
                }
              });
              if (a == 0 && el.JobHead_EquipID != "") {
                equipList.push({
                  equipID: el.JobHead_EquipID,
                  equipName: el.JobHead_EquipID,
                });
              }
              break;
            case "r":
              var b = 0;
              resourceDataSource.forEach((el1) => {
                if (el.Resource_ResourceID == el1.resourceID) {
                  b = b + 1;
                }
              });
              if (b == 0 && el.Resource_ResourceID != "") {
                resourceDataSource.push({
                  resourceID: el.Resource_ResourceID,
                  resourceName: el.Resource_Description,
                });
              }
              break;
            case "rg":
              var c = 0;
              resourceGroup.forEach((el1) => {
                if (el.JobOpDtl_ResourceGrpID == el1.groupID) {
                  c = c + 1;
                }
              });
              if (c == 0 && el.JobOpDtl_ResourceGrpID != "") {
                resourceGroup.push({
                  groupID: el.JobOpDtl_ResourceGrpID,
                  groupName: el.JobOpDtl_ResourceGrpID,
                });
              }
              break;
            case "p":
              var d = 0;
              projectList.forEach((el1) => {
                if (el.JobHead_ProjectID == el1.projectID) {
                  d = d + 1;
                }
              });
              if (d == 0 && el.JobHead_ProjectID != "") {
                projectList.push({
                  projectID: el.JobHead_ProjectID,
                  projectName: el.JobHead_ProjectID,
                });
              }
              break;
          }
        }
      });
      switch (this.getSelectionType) {
        case "e":
          this.resourceDataSource = equipList;
          this.textField = "equipName";
          this.idField = "equipID";
          this.field = "equipID";
          break;
        case "r":
          this.resourceDataSource = resourceDataSource;
          this.textField = "resourceName";
          this.idField = "resourceID";
          this.field = "resourceID";
          break;
        case "rg":
          this.resourceDataSource = resourceGroup;
          this.textField = "groupName";
          this.idField = "groupID";
          this.field = "groupID";
          break;
        case "p":
          this.resourceDataSource = projectList;
          this.textField = "projectName";
          this.idField = "projectID";
          this.field = "projectID";
      }
      this.eventSettings = { dataSource: jobs };
    },
    generateDistinctColors(count) {
      if (count <= 0) return;
      const colors = [
        "#c50001",
        "#048aa9",
        "#9dd482",
        "#15c2f4",
        "#f4ca6d",
        "#025064",
      ];
      this.colors = colors;
    },
    dragStart(item) {
      this.curItem = item.data;
    },
    dragStop(item) {
      this.a = this.$refs.scheduleObj.openEditor(item.data, "Save");
      item.data = this.curItem;
    },
    onEventRendered: function (args) {
      switch (args.data.priority.toLowerCase()) {
        case "high":
          args.element.style.backgroundColor = this.colors[0];
          break;
        case "immed":
        case "immediate":
          args.element.style.backgroundColor = this.colors[1];
          break;
        case "low":
          args.element.style.backgroundColor = this.colors[2];
          break;
        case "normal":
          args.element.style.backgroundColor = this.colors[3];
          break;
        case "vhigh":
        case "very high":
          args.element.style.backgroundColor = this.colors[4];
          break;
        case "vlow":
        case "very low":
          args.element.style.backgroundColor = this.colors[5];
          break;
      }
      let targetsub = args.element.querySelector(".e-subject");
      let a = Math.floor(Math.random() * 2) + 1;
      let stringclass = "";
      if (a == 1) stringclass = "fa fa-certificate";
      if (a == 2) stringclass = "fa fa-shield";
      if (a == 3) stringclass = "fa fa-outdent";
      targetsub.innerHTML = `<div class='e-subject' style="margin-top:0;padding-top: 2px;text-align: start;"><i class="${stringclass}" style="font-size=10px;color="white"></i> ${targetsub.textContent}</div>`;
    },
    onActionBegin: function (args) {
      if (args.requestType == "eventChange") {
        // let weekEnds = [0, 6];
        // let weekDay = weekEnds.indexOf((args.data.StartTime).getDay()) >= 0;
        // let workHours =  (args.data.StartTime.getHours < parseInt(this.$refs.scheduleObj.workHours.start)) || (args.data.StartTime.getHours > parseInt(this.$refs.scheduleObj.workHours.end));
        // if(weekDay || workHours){
        //     args.cancel = true;
        // }
      }
    },
    onRenderCell: function (args) {
      document.querySelectorAll(".e-tbar-btn-text").forEach(function (span) {
        if (
          span &&
          span.textContent &&
          span.textContent.toLowerCase().trim() == "agenda"
        ) {
          span.textContent = "List View";
        }
      });
      if (args.element.classList.contains("e-child-node")) {
        if (this.getSelectionType != "e") {
          let target = args.element.querySelector(".e-resource-text");
          if (target)
            switch (this.b % 3) {
              case 0:
                target.innerHTML = `<div class="name"><img class="employee-image" src="https://ik.imagekit.io/tiuh2lft4/Worker/depositphotos_255866600-stock-photo-waist-portrait-bearded-factory-worker.jpg?updatedAt=1692094350067"/>${target.textContent}</div>`;
                break;
              case 1:
                target.innerHTML = `<div class="name"><img class="employee-image" src="https://ik.imagekit.io/tiuh2lft4/Worker/366755710_204684345658336_3102417813962127750_n.png?updatedAt=1692094099632"/>${target.textContent}</div>`;
                break;
              case 2:
                target.innerHTML = `<div class="name"><img class="employee-image" src="https://ik.imagekit.io/tiuh2lft4/Worker/OIP.jpeg?updatedAt=1692094063013"/>${target.textContent}</div>`;
                break;
            }
          this.b++;
        } else {
          let target = args.element.querySelector(".e-resource-text");
          if (target) {
            let a = this.getEquipments.find((el) => {
              return el.equipName == target.textContent;
            });
            if (a.base64 != "") {
              target.innerHTML = `<div class="name"><img class="employee-image" src="data:image/jpeg;base64,${a.base64}" />${target.textContent}</div>`;
            } else {
              target.innerHTML = `<div class="name"><img class="employee-image" src="https://ik.imagekit.io/v57xrgddo/WhatsApp%20Image%202023-10-11%20at%2014.49.56.jpeg?updatedAt=1697015322006" />${target.textContent}</div>`;
            }
          }
          this.b++;
        }
      }
    },
    dataBound(args) {
      console.log(args);
      // let scheduleObj = this.$refs.scheduleObj.ej2Instances;
      if (initialLoad) {
        // setTimeout(() => {
        //   document.querySelector('.e-all-day-appointment-section').click();
        // }, 0)
        initialLoad = false;
      }
    },
  },
};
</script>

<style lang="scss">
@import "../../../../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../../../../node_modules/@syncfusion/ej2-vue-schedule/styles/material.css";
@import "../../../../node_modules/@syncfusion/ej2-buttons/styles/material.css";
@import "../../../../node_modules/@syncfusion/ej2-calendars/styles/material.css";
@import "../../../../node_modules/@syncfusion/ej2-dropdowns/styles/material.css";
@import "../../../../node_modules/@syncfusion/ej2-inputs/styles/material.css";
@import "../../../../node_modules/@syncfusion/ej2-navigations/styles/material.css";
@import "../../../../node_modules/@syncfusion/ej2-popups/styles/material.css";
@import "../../../assets/css/standard.scss";

.employee-image {
  width: 45px;
  height: 40px;
  float: left;
  border-radius: 50%;
  margin-right: 10px;
}

#colorbar {
  display: flex;
  height: 10vh;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-end;
}

.colorbar_sub {
  display: flex;
  height: 70%;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-around;
  padding-bottom: 10px;
}

.priority {
  font-size: 12px !important;
  height: auto;
  margin-bottom: 0 !important;
  display: flex;
  margin-right: 15px;
}

.priority .v-label {
  font-size: 12px;
}

::v-deep .priority .v-label {
  font-size: 12px;
  cursor: default !important;
}

.name {
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;
}
</style>
